<template lang="pug">
    div
        loader(v-if="$root.loader")
        template(v-else)
            v-snackbar(
                :timeout="3000",
                :top="true"
                :right="true"
                :color="alertColor"
                v-model="alert")
                    | {{ alertText }}
                    v-btn(flat, dark, @click.native="alert = false") {{$t('cerrar')}}
            header-component
            v-content
                v-container.grid-list-xl(fluid)
                    h1 {{$t('Mensajes')}}
                    v-layout(row wrap)
                        v-flex(xs12 sm3)
                            v-navigation-drawer.message-list-drawer(width="100%" permanent)
                                v-text-field.search-friend(v-model="search" :label="$t('Buscar amigo')")
                                .my-2.text-xs-center(v-if="loading")
                                    v-progress-circular.text-xs-center(:size="24" indeterminate color="primary")
                                .message-list
                                    v-list.my-2.pt-0(dense='')
                                        v-list-tile.cur-p(@click="createChat(friend)" v-for="(friend, i) in friends" :key="'f'+i")
                                            v-list-tile-action
                                                v-avatar.grey.lighten-4(size='27')
                                                    img(v-if="photoUrl(friend.photo).length" :src="photoUrl(friend.photo)")
                                                    v-gravatar(v-else :email="friend.email")
                                            v-list-tile-content {{ friend.names }}
                                    v-divider.my-2(v-if="friends.length")
                                    h4.pb-0 {{ $t('Chats') }}
                                    .my-2.text-xs-center(v-if="loadChats")
                                        v-progress-circular.text-xs-center(:size="24" indeterminate color="primary")
                                    template(v-else)
                                        .not-found.mx-auto.text-xs-center.my-4(v-if="!chats.length")
                                            h3 {{ $t('No se encontraron chats') }}
                                        v-list.pt-0.mt-2(v-else dense='')
                                            v-list-tile.cur-p(@click="active = chat,readChat(chat)" :class="{'active': Object.keys(active).length && active.users.includes(chat.current.uuid)}" v-for="(chat, i) in chats" :key="'cht'+i")
                                                v-list-tile-action
                                                    v-avatar.grey.lighten-4(size='27')
                                                        img(v-if="photoUrl(chat.current.photo).length" :src="photoUrl(chat.current.photo)")
                                                        v-gravatar(v-else :email="chat.current.email")
                                                v-list-tile-content
                                                    b(v-if="!chat.read && chat.notify_to == profile.uuid") {{ chat.current.names }}
                                                    span(v-else) {{ chat.current.names }}
                        v-flex(xs12 sm9 v-if="chats.length")
                            template(v-if="!Object.keys(active).length")
                                .not-found.mx-auto.text-xs-center.my-5
                                    h3 {{ $t('Seleccione un chat') }}
                            .full-width.message-container(v-else)
                                h4 {{ active.current.names }}
                                .my-2.text-xs-center(v-if="loadingMsg")
                                    v-progress-circular.text-xs-center(:size="24" indeterminate color="primary")
                                div.my-4.message-log#message-log(@scroll="fetchMoreMessages")
                                    .not-found.mx-auto.text-xs-center.my-4(v-if="!loadingMsg && !messages.length")
                                        h3 {{ $t('No se encontraron mensajes') }}
                                    div.message.py-2.my-1(:class="{'me': message.author == me}" v-for="(message, i) in messages" :key="'mg'+i")
                                        small(v-if="message.author != me")
                                            v-avatar.mr-2.grey.lighten-4(size='12')
                                                img(v-if="photoUrl(active.current.photo).length" :src="photoUrl(active.current.photo)")
                                                v-gravatar(v-else :email="active.current.email")
                                            | {{ active.current.names }}
                                        small(v-else) {{ $t('Tú') }}
                                        p.mb-1 {{ message.message }}
                                div.message-box.d-flex(v-if="!loadingMsg")
                                    v-text-field(@keyup.enter="sendMsg" multi-line rows="2" :label="$t('Ingrese un mensaje')" outline v-model="msgtext")
                                    v-btn.pa-3(:loading="loaderSend" :disabled="loaderSend" transition="slide-x-transition" @click="sendMsg" v-if="msg.length" color="primary")
                                        | {{ $t('Enviar') }}
                                        v-icon.ml-2 send
</template>

<script>

    import auth from 'mixins/auth'
    import config from 'src/config'
    import _ from 'lodash/debounce'
    import fn from 'mixins/fn'

    export default {
        mixins: [auth, fn],
        metaInfo() {
            return {
                title: this.$t('Mensajes')
            }
        },
        data() {
            return {

                // General
                alert        : false,
                alertText    : '',
                alertColor   : '',

                active  : {},
                msgtext : '',
                search  : '',
                chats   : [],

                // Amigos
                loading     : false,
                cancelFilter: null,
                filter      : {},
                preload     : false,
                friends     : [],
                offset      : 0,

                // Chats
                loadingMsg : false,
                loaderSend : false,
                loadChats  : false,
                messages   : [],
                limitLog   : 10,
                offsetLog  : 0
            }
        },
        computed: {
            msg(){
                return this.msgtext.trim()
            },
            me(){
                return this.$root.profile.uuid
            },
            profile(){
                return this.$root.profile
            }
        },
        watch: {
            search() {

                this.friends = []

                if(this.search.trim().length > 3){
                    this.fetchFriends()
                }
            },
            active(){

                this.offsetLog = 0
                this.fetchMessages()
            },
            profile: {
                deep: true,
                async handler(){

                    // Estado del chat
                    this.handleChannel('/chat', (dat) => {

                        // Crear nuevo chat si no existe
                        if(!this.chats.find(c => c.users.includes(dat.current.uuid))){

                            this.chats.unshift({
                                users   : dat.users,
                                read    : dat.read,
                                current : {
                                    uuid  : dat.current.uuid,
                                    names : dat.current.names,
                                    email : dat.current.email,
                                    photo : dat.current.photo
                                }
                            })

                        // Enviar mensajes a chat activo
                        }else if(this.active.hasOwnProperty('users') && this.active.users.includes(dat.current.uuid)){

                            this.messages.push({
                                author  : dat.current.uuid,
                                message : dat.msg,
                                time    : dat.time
                            })

                            this.$nextTick(() => {
                                this.offsetLog += 1
                                this.resetScroll()
                            })
                        }
                    })

                    this.handleChannel('/chat/unread', (dat) => {

                        let findChat = ((c) => c.users.includes(dat.userid) && dat.userid != this.profile.uuid)
                        let chat = this.chats.find(findChat)
                        let chatIdx = this.chats.findIndex(findChat)
                        let isActive = this.active.hasOwnProperty('current') && this.active.current.uuid == dat.userid

                        if(chat){

                            if(isActive){
                                this.readChat(chat, true)
                            }else{
                                chat.read = false
                            }

                            // Ordenar posición de chats
                            this.chats.splice(chatIdx, 1)
                            this.chats.unshift(chat)
                        }
                    })
                }
            }
        },
        created(){
            this.fetchChats()
        },
        methods: {

            resetScroll(){

                let log = document.getElementById('message-log')
                log.scrollTop = log.scrollHeight
            },

            photoUrl(photo){

                if(photo != null && photo.length){
                    return config.uploaduser + photo
                }
                return ''
            },

            fetchFriends: _(function(){

                if(this.cancelFilter != null){
                    this.cancelFilter.cancel()
                }

                let CancelToken   = this.$axios.CancelToken
                this.cancelFilter = CancelToken.source()

                this.loading = true
                this.$api(this, (xhr) => {
                    xhr.get('/social/friend/list', {
                        params: {
                            filter : JSON.stringify(this.filter),
                            chat   : 1
                        }
                    }).then((r) => {

                        let data     = r.data
                        this.preload = false
                        this.loading = false

                        if(data.response){
                            this.friends = data.data
                        }
                    }).catch(() => {})
                })

            }, 500),

            sendMsg(e){

                if(e.shiftKey){
                    return
                }

                if(!this.msg.length){
                    return
                }

                this.loaderSend = true
                this.$api(this, (xhr) => {
                    xhr.post('/social/chat/send', this.$qs.stringify({
                        userid  : this.active.current.uuid,
                        msg     : this.msg
                    })).then((r) => {

                        let data    = r.data
                        this.loaderSend = false

                        if(data.response){

                            this.messages.push({
                                author  : this.me,
                                message : this.msg,
                                time    : data.time
                            })

                            this.msgtext = ''
                            this.$nextTick(() => {
                                this.resetScroll()
                            })

                        }else{
                            
                            this.alert  = true
                            this.alertText  = this.$t(data.message)
                            this.alertColor = 'error'
                        }
                    }).catch(() => this.loaderSend = false)
                })
            },

            createChat({uuid, names, email, photo}){
                
                let chat = {
                    users   : [uuid, this.$root.profile.uuid],
                    read    : false,
                    current : {
                        uuid,
                        names,
                        email,
                        photo
                    }
                }

                this.active = chat
                this.search = ''
                
                if(!this.chats.some((c) => {
                    return c.users.includes(uuid)
                })){
                    this.chats.unshift(chat)
                }

                this.$api(this, (xhr) => {
                    xhr.post('/social/chat/create', this.$qs.stringify({
                        userid: uuid
                    })).then((r) => {

                        let data    = r.data
                        if(!data.response){
                            
                            this.alert  = true
                            this.alertText  = this.$t(data.message)
                            this.alertColor = 'error'
                        }
                    }).catch(() => {})
                })
            },

            fetchMessages(scroll = false){

                this.loadingMsg = true
                this.$api(this, (xhr) => {
                    xhr.get('/social/chat/log', {
                        params: {
                            userid : this.active.current.uuid,
                            limit  : this.limitLog,
                            offset : this.offsetLog,
                        }
                    }).then((r) => {

                        this.loadingMsg = false
                        let data = r.data
                        if(data.response){
                            this.messages = scroll ? [...data.data, ...this.messages] : data.data
                            this.$nextTick(() => {
                                if(!scroll){
                                    this.resetScroll()
                                }
                            })
                        }

                    }).catch(() => {
                        this.loadingMsg = false
                    })
                })
            },

            fetchMoreMessages(e){

                if(e.target.scrollTop == 0){

                    this.offsetLog += this.limitLog
                    this.fetchMessages(true)
                }
            },

            fetchChats(){

                this.loadChats = true
                this.$api(this, (xhr) => {
                    xhr.get('/social/chat/list').then((r) => {

                        this.loadChats = false
                        let data = r.data
                        if(data.response){
                            this.chats = data.data
                        }

                    }).catch(() => {
                        this.loadChats = false
                    })
                })
            },

            readChat(chat, active = false){
                
                if(!active && chat.read){
                    return
                }

                this.$api(this, (xhr) => {
                    xhr.post('/social/chat/read', this.$qs.stringify({
                        users : JSON.stringify(chat.users)
                    })).then((r) => {

                        let data = r.data
                        if(data.response){
                            chat.read = true
                        }

                    }).catch(() => {})
                })
            }
        }
    }

</script>